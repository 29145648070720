import React, { useContext } from 'react';
import { Context } from 'src/context';

const TYPE_CLIENT_DEALERSHIP = 'client_dealership'
const IGARAPE_ID = 'b2e6cad2-15f6-4c73-80f8-cf0546035348'

export default function useBranchTypeModel() {
  const { branches } = useContext(Context);
  const { selectBranch } = useContext(Context);

  const isBranchTypeClientAndDealership = (branch: string) => {
    return branches.find(item => item.id === branch)?.type_model === TYPE_CLIENT_DEALERSHIP
  }

  const isBranchIgarape = () => {
    return branches.find(item => item.id === selectBranch?.id)?.id === IGARAPE_ID
  }

  return { isBranchTypeClientAndDealership, isBranchIgarape };
}
