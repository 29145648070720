import { useCallback, useEffect, useState } from 'react';
import api from 'src/services';
import * as Sentry from '@sentry/react';
import { IClient } from './useUsers';
import { ITypeUserAuthenticated } from './useAuth';

export interface IBranch {
  id: string;
  name: string;
  type_model: string;
}

export interface IRegisterClients {
  fantasy: string;
  name: string;
  cnpj: string;
  street: string;
  number: string;
  complement: string;
  cep: string;
  district: string;
  city: string;
  state: string;
  access?: any;
}

export interface IFilterUsers {
  name?: string;
  email?: string;
  branchs?: string[];
  page?: number;
  perPage?: number;
}

export default function useGlobalHooks(userAuth?: ITypeUserAuthenticated | null) {
  const [selectClientsAccess, setSelectClientsAccess] = useState<IClient[] | []>([]);
  const [selectBranch, setSelectBranch] = useState<IBranch>();
  const [availableBranches, setAvailableBranches] = useState<IBranch[]>([]);
  const [branches, setBranches] = useState<IBranch[]>([]);

  const handleGetBranchs = useCallback(async () => {
    if (!userAuth) return;
    try {
      const { data: branches }: any = await api.branchs().getBranchsUser();
      setBranches(branches);
      setAvailableBranches(branches);
    } catch (err) {
      Sentry.captureException(err);
    }
  }, [userAuth]);

  return {
    selectClientsAccess,
    handleGetBranchs,
    selectBranch,
    availableBranches,
    setSelectBranch,
    branches
  };
}
