import React from 'react';
import ReactDOM from 'react-dom/client';
import './styles/main.css';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import reportWebVitals from './reportWebVitals';
import App from './App';
import { msalConfig } from './config/authAzure';
import './config/reactDatePicker';

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY,
  environment: process.env.REACT_APP_ENVIRONMENT,
  integrations: [new Integrations.BrowserTracing()],
  tracesSampleRate: Number(process.env.REACT_APP_SAMPLE_RATE_SENTRY || 1.0),
});

const msalInstance = new PublicClientApplication(msalConfig);

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <React.StrictMode>
    <MsalProvider instance={msalInstance}>
      <App />
    </MsalProvider>
  </React.StrictMode>
);

reportWebVitals();